<template>
    <div class="live-other-info">
        <div class="container">
            <live-popularity class="popularity" :data="popularityData" :liveState="liveState" :liveTime="liveTime"></live-popularity>
            <live-bullet-chat class="bullet-chat" :list="bulletChatList" :liveState="liveState"></live-bullet-chat>
        </div>
    </div>
</template>

<script>
import LivePopularity from "./liveOtherInfo/LivePopularity";
import LiveBulletChat from "./liveOtherInfo/LiveBulletChat";
export default {
    name: "LiveOtherInfo",
    data() {
        return {};
    },
    props: {
        popularityData: {
            type: Object,
            default: () => {},
        },
        bulletChatList: {
            type: Array,
            default: () => [],
        },
        liveState: {
            type: Number,
            default: 0,
        },
        liveTime: {
            type: Object,
            default: () => null,
        },
    },
    computed: {},
    components: {
        LivePopularity,
        LiveBulletChat,
    },
    methods: {},
    mounted() {},
};
</script>

<style scoped lang="scss">
.live-other-info {
    box-sizing: border-box;
    flex: 4;
    .container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .popularity {
            flex: 4.65;
            margin-bottom: 20px;
            background-color: #0c0e3f;
        }
        .bullet-chat {
            flex: 5.35;
            background-color: #0c0e3f;
        }
    }
}
</style>
